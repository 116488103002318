import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import HeaderBottom from "../components/HeaderBottom";

import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const blog = data.blog;
  const blogContent = data.blog.frontmatter;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;
  const commentsLength = blogContent.comments && blogContent.comments.length;

  console.log("data", data.blog);

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={blog.frontmatter.title}
        // description={blog.frontmatter.description || post.excerpt}
      />
      <Helmet
        bodyAttributes={{
          class: "scroll-is--active",
        }}
      />
      <HeaderBottom />
      <main class="site-content" id="wrapper">
        <div class="site-content__inner">
          <div class="site-content__holder">
            <article class="post post--single">
              <figure class="post__thumbnail">
                <img
                  src={
                    blogContent.mainImage
                      ? blogContent.mainImage
                      : "/assets/img/samples/news-post-img-01.jpg"
                  }
                  alt=""
                />
              </figure>
              <ul class="post__sharing">
                <li class="post__sharing-item post__sharing-item--menu">
                  <Link to="/products">
                    <i>&nbsp;</i>
                  </Link>
                </li>
                <li class="post__sharing-item">
                  <a
                    href={data.site.siteMetadata.whatsappUrl}
                    target="__blank"
                  ></a>
                </li>
                <li class="post__sharing-item">
                  <a
                    href={data.site.siteMetadata.tokopediaUrl}
                    target="__blank"
                  ></a>
                </li>
                <li class="post__sharing-item post__sharing-item--comments">
                  <a href="#comments">
                    <span>{commentsLength}</span>
                    <svg role="img" class="df-icon df-icon--comments-small">
                      <use href="/assets/img/necromancers.svg#comments-small" />
                    </svg>
                  </a>
                </li>
              </ul>
              <div class="post__header">
                <div class="post__cats h6">
                  <span class="color-warning">{blogContent.category}</span>
                </div>
                <h2 class="post__title h3">{blogContent.title}</h2>
                <div class="post__meta">
                  <span class="meta-item meta-item--date">
                    {blogContent.date}
                  </span>
                </div>
              </div>
              <div
                class="post__body"
                dangerouslySetInnerHTML={{ __html: data.blog.html }}
              />
            </article>
            <hr className="my-5" />
            <div class="post-comments" id="comments">
              <h4 class="post-comments__title">{`Comments (${commentsLength})`}</h4>
              <ol class="comments">
                {blogContent.comments &&
                  blogContent.comments.map((comment, index) => {
                    return (
                      <li class="comment">
                        {/* <div class="comment__avatar">
                          <img
                            src="/assets/img/samples/news-post-img-03.jpg"
                            alt=""
                          />
                        </div> */}
                        <div class="comment__body">
                          <h6 class="comment__author">{comment.commenter}</h6>
                          <p>{comment.comment}</p>
                          <div class="comment__meta">
                            <time class="comment__date">{comment.date}</time>
                            {/* <div class="comment__reply">
                              <a href="#">Reply</a>
                            </div> */}
                          </div>
                        </div>
                        <ul class="children">
                          <li class="comment">
                            {/* <div class="comment__avatar">
                              <img
                                src="/assets/img/samples/news-post-img-05.jpg"
                                alt=""
                              />
                            </div> */}
                            <div class="comment__body">
                              <h6 class="comment__author">
                                {data.site.siteMetadata.companyName}
                              </h6>
                              <p>{comment.reply}</p>
                              <div class="comment__meta">
                                <time class="comment__date">
                                  {comment.date}
                                </time>
                                {/* <div class="comment__reply">
                                  <a href="#">Reply</a>
                                </div> */}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    );
                  })}
              </ol>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        companyName
        tokopediaUrl
        whatsappUrl
      }
    }
    blog: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY", locale: "id", fromNow: true)
        category
        mainImage
        comments {
          comment
          commenter
          date(formatString: "MMMM DD, YYYY", locale: "id", fromNow: true)
          reply
        }
      }
    }
  }
`;

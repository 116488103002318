import React, { Component } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { useMediaQuery } from "react-responsive";

const Header = (props) => {
  const isHeaderTop = useMediaQuery({ query: "(max-width: 992px)" });

  const data = useStaticQuery(graphql`
    query HeaderBottomQuery {
      linktree: markdownRemark(frontmatter: { id: { eq: "linktree" } }) {
        frontmatter {
          id
          links {
            label
            url
          }
        }
      }
      markdownRemark(frontmatter: { id: { eq: "home" } }) {
        frontmatter {
          logo
          logoHorizontal
          pictureLogo
          brands {
            id
            name
          }
          tags {
            id
            name
          }
        }
      }
    }
  `);
  // console.log(
  //   "tex",
  //   data.markdownRemark.frontmatter,
  //   data.linktree.frontmatter,
  //   props.location.path,
  //   props.location
  // );
  // let brands = data.markdownRemark.frontmatter.brands
  // let tags = data.markdownRemark.frontmatter.tags
  // let ecommerce = data.linktree.frontmatter.links
  // console.log('categories', tags)
  return (
    <header id="header" class="site-header site-header--bottom">
      <div class="header-logo header-logo--img">
        <Link to="/">
          <img
            // src={isHeaderTop ? data.markdownRemark.frontmatter.logoHorizontal : data.markdownRemark.frontmatter.logo}
            // srcSet={isHeaderTop ? data.markdownRemark.frontmatter.logoHorizontal : data.markdownRemark.frontmatter.logo}
            src={data.markdownRemark.frontmatter.logoHorizontal}
            srcSet={data.markdownRemark.frontmatter.logoHorizontal}
            alt="Apex Warrior Logo"
          />
        </Link>
      </div>

      <nav class="main-nav">
        <ul class="main-nav__list">
          <li class="active">
            <Link to="/products">Home</Link>
          </li>
          <li class="">
            <Link to="/products">Products</Link>
            <ul class="main-nav__sub">
              <li class="">
                <Link to="/products/tag=gchair">Gaming Chair</Link>
              </li>
              {/* <li class="">
                <a href="shop-2.html">Gaming Tables</a>
              </li>
              <li class="">
                <a href="shop-product.html">Product Page</a>
              </li>
              <li class="">
                <a href="shop-checkout.html">Checkout Page</a>
              </li> */}
            </ul>
          </li>
          <li class="">
            <Link to="/blogs">Articles</Link>
            <ul class="main-nav__sub">
              <li class="">
                <Link to="/blogs">All Articles</Link>
              </li>
              <li class="">
                <Link to="/blogs">Kenapa Kita?</Link>
              </li>
              <li class="">
                <Link to="/blogs">Ergonomics</Link>
              </li>
            </ul>
          </li>
          <li class="">
            <Link to="/about">About Us</Link>
          </li>
          <li class="">
            <Link to="/contact">Contact Us</Link>
            <ul class="main-nav__sub">
              <li class="">
                <Link to="/contact">Contact Page</Link>
              </li>
              <li class="">
                <a href="https://wa.me/message/LGXWX5CG54JLE1">Whatsapp</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <div class="header-actions">
        {/* <div class="header-pagination">
          <a href="#" class="header-scroll-arrow header-scroll-arrow--left">
            <svg role="img" class="df-icon df-icon--left-arrow">
              <use href="/assets/img/necromancers.svg#left-arrow" />
            </svg>
          </a>
          <ol class="header-pagination--list">
            <li>
              <a href="#">01</a>
            </li>
            <li>
              <a href="#">02</a>
            </li>
            <li>
              <span>...</span>
            </li>
            <li>
              <a href="#">08</a>
            </li>
          </ol>
          <a href="#" class="header-scroll-arrow header-scroll-arrow--right">
            <svg role="img" class="df-icon df-icon--right-arrow">
              <use href="/assets/img/necromancers.svg#right-arrow" />
            </svg>
          </a>
        </div>
        <div class="header-filter-toggle">
          <svg role="img" class="df-icon df-icon--filter">
            <use href="/assets/img/necromancers.svg#filter" />
          </svg>
          <div class="filter-menu">
            <form action="#" class="filter-menu__form">
              <div class="filter-menu__select">
                <label class="filter-menu__label">Category</label>
                <select class="cs-select">
                  <option value="" selected>
                    All Categories
                  </option>
                  <option value="">Esports</option>
                  <option value="">Gaming</option>
                  <option value="">Videos</option>
                </select>
              </div>
              <div class="filter-menu__select">
                <label class="filter-menu__label">Filter By</label>
                <select class="cs-select">
                  <option value="" selected>
                    Article Date
                  </option>
                  <option value="">Comments</option>
                  <option value="">Popularity</option>
                </select>
              </div>
              <div class="filter-menu__select">
                <label class="filter-menu__label">Order</label>
                <select class="cs-select">
                  <option value="" selected>
                    Ascending
                  </option>
                  <option value="">Descending</option>
                </select>
              </div>
              <div class="filter-menu__submit">
                <button type="submit" class="btn btn-primary btn-sm btn-block">
                  Filter News
                </button>
              </div>
            </form>
          </div>
        </div> */}
        <div class="header-top-bar-toggle d-md-none hide">
          <svg role="img" class="df-icon df-icon--joystick">
            <use href="/assets/img/necromancers.svg#joystick" />
          </svg>
          <svg role="img" class="df-icon df-icon--close">
            <use href="/assets/img/necromancers.svg#close" />
          </svg>
        </div>
        <div class="header-social-toggle d-none d-md-block">
          <svg role="img" class="df-icon df-icon--thumb-up">
            <use href="/assets/img/necromancers.svg#thumb-up" />
          </svg>
          <span class="header-social-toggle__plus">&nbsp;</span>
          <ul class="social-menu social-menu--header">
            <li>
              <a href="https://www.facebook.com/Apex-Warrior-104900274938867/">
                <span class="link-subtitle">Facebook</span>Facebook
              </a>
            </li>
            <li>
              <a href="https://instagram.com/apexwarrior.id">
                <span class="link-subtitle">Instagram</span>Instagram
              </a>
            </li>
            <li>
              <a href="https://wa.me/message/LGXWX5CG54JLE1">
                <span class="link-subtitle">Whatsapp</span>Chat
              </a>
            </li>
            <li>
              <a href="https://tokopedia.com/apexwarrior">
                <span class="link-subtitle">Tokopedia</span>Shop
              </a>
            </li>
          </ul>
        </div>
        {/* <div class="header-search-toggle">
          <svg role="img" class="df-icon df-icon--search">
            <use href="/assets/img/necromancers.svg#search" />
          </svg>
          <svg role="img" class="df-icon df-icon--search-close">
            <use href="/assets/img/necromancers.svg#search-close" />
          </svg>
        </div> */}
        {/* <div class="header-account hide">
          <div class="header-account__avatar">
            <img
              src="/assets/img/samples/account-user-avatar.jpg"
              srcset="/assets/img/samples/account-user-avatar@2x.jpg 2x"
              alt=""
            />
          </div>
          <div class="header-account__body">
            Hello!
            <div class="header-account__name">James Spiegel</div>
          </div>
          <div class="header-account__icon">
            <a href="shop-account-settings.html">
              <svg role="img" class="df-icon df-icon--account">
                <use href="/assets/img/necromancers.svg#account" />
              </svg>
            </a>
            <a href="login-register.html">
              <svg role="img" class="df-icon df-icon--logout">
                <use href="/assets/img/necromancers.svg#logout" />
              </svg>
            </a>
          </div>
        </div> */}
        <div class="header-menu-toggle">
          <div class="header-menu-toggle__inner">
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
